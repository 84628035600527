import { Box, Button, Divider, IconButton, Stack, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import { useContext, useEffect, useState } from "react";
import { fCurrency } from "utils/formatNumber";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { path } from "config/path";
import { StoreContext } from "layout/HomeLayout/index";
import { DeleteForever } from "@mui/icons-material";
import { DeleteImage } from "utils/util";
// CART
const Cart = ({ toggleCart, setIsOpenCart }) => {
	// HOOKS
	const theme = useTheme();
	const navigate = useNavigate();
	const { store, cart, setCart } = useContext(StoreContext);
	// DATA

	// FUNCTIONS
	const handleClose = () => {
		setIsOpenCart(false);
	};

	const handleCheckout = () => {
		handleClose();
		navigate(path.urls.checkout);
	};

	const handleRemove = (index) => {
		const isConfirm = window.confirm("Do you want to remove this item from cart?");
		if (isConfirm) {
			if (cart[index].customImage) {
				DeleteImage(cart[index].customImage.id);
			}
			const newCartData = cart.filter((item, i) => i !== index);
			setCart(newCartData);
			if (newCartData.length==0) setIsOpenCart(false);
		}
	};

	const handleRemoveTopping = (index) => {
		const isConfirm = window.confirm("Do you want to remove this topping from cart?");
		if (isConfirm) {
			const newCartItem = cart.map((item, i) => {
				if (i === index) {
					item.topping = null;
				}
				return item;
			});
			setCart(newCartItem);
		}
	};

	const emptyBasket = () => {
		const isConfirm = window.confirm("Do you want to empty the basket?");
		if (isConfirm) {
			cart.map((item, index)=>{
				if (item.customImage) {
					DeleteImage(item.customImage.id);
				}
			})
			setCart([]);
			handleClose();
		}
	};

	//console.log(cart);
	// RENDER
	return (
		<Stack
			id="cart-drawer"
			direction="column"
			justifyContent="space-between"
			sx={{
				top: 0,
				// height: "100%",
				width: "100%",
				background: theme.palette.background.default,
			}}
			spacing={2}>
			<Stack direction="row" justifyContent="space-between" sx={{ alignItems: "center" }}>
				<Typography variant="h4" sx={{ p: 2, textAlign: "start" }}>
					Shopping Cart {!isEmpty(cart) && ` | ${cart.reduce((acc, item) => acc + item.quantity, 0)} item${cart.length > 1 ? "s" : ""}`}
				</Typography>
				<IconButton onClick={emptyBasket} sx={{ width: 40, height: 40 }}>
					<DeleteForever color="error" />
				</IconButton>
			</Stack>
			<Stack sx={{ overflowY: "auto", flexGrow: 1, textAlign: "start" }} spacing={2}>
				{cart.map((item, index) => (
					<Stack key={`${item.id}-${index}`} spacing={2}>
						<Stack direction="row" spacing={2} sx={{ px: 2, "& .MuiTypography-root": { textTransform: "capitalize" } }}>
							{/* Image */}
							<Stack sx={{ width: 80 }}>
								<Box >
									<img
										src={item.image ? `${path.imageServer}${path.imageDirectory}/${store.key}/${item.image}` : `${path.basename}/images/icon-product.svg`}
										alt={item.name} style={{ width: "100%", height: "auto", objectFit: "cover" }}
									/>
								</Box>
								{ item.customImage && (
									<Box>
										<img
										src={`${path.ciHost}/uploads/${store.id}/` + item.customImage.filename}
										style={{ width: "100%", height: "auto", objectFit: "cover" }}
										/>
									</Box>
								)}
							</Stack>
							<Stack direction="column" sx={{ flexGrow: 1 }}>
								<Stack direction="row" justifyContent="space-between" sx={{ mb: 1 }}>
									<Typography variant="h5" sx={{ width: "70%" }}>
										{item.name}
									</Typography>
									<Typography variant="h5">£{fCurrency((Number(item.final_price)) * item.quantity)}</Typography>
								</Stack>
								{ item.flavour && (
									<Typography variant="body1">Options: {item.flavour}</Typography>
								)}
								<Typography variant="body1">Size: {item.size}</Typography>
								{ item.color && (
									<Typography variant="body1">Color: {item.color}</Typography>
								)}
								{ item.customMsg && (
									<Typography variant="body1">Event Type: {item.customMsg}</Typography>
								)}
								<Typography variant="body1">Quantity: {item.quantity}</Typography>
								<Stack direction="row" justifyContent="flex-end">
									<Button variant="text" onClick={() => handleRemove(index)}>
										<Typography variant="body1" sx={{ color: "black", textDecoration: "underline", fontWeight: "bold" }}>
											Remove
										</Typography>
									</Button>
								</Stack>
							</Stack>
						</Stack>
						{item?.topping && (
							<Stack sx={{ px: 2 }} spacing={2}>
								<Divider sx={{ borderColor: theme.palette.grey[400] }} >Extra Topping</Divider>
								{ item.topping.id && (
								<Stack direction="row" spacing={2} sx={{ "& .MuiTypography-root": { textTransform: "capitalize" } }}>
									{/* Image */}
									<Box sx={{ width: 80, minWidth: 80 }}>
										<img
											src={item.topping?.image ? `${path.imageServer}${path.imageDirectory}/${store.key}/${item.topping?.image}` : `${path.basename}/images/icon-product.svg`}
											alt={item.topping?.name} style={{ width: "100%", height: "auto", objectFit: "cover" }}
										/>
									</Box>
									{/* Topping Details */}
									<Stack sx={{ flexGrow: 1 }}>
										<Stack direction="row" justifyContent="space-between">
											<Typography variant="body1">{item.topping?.size}</Typography>
											<Typography variant="body1">£{fCurrency(item.topping?.sale_price)}</Typography>
										</Stack>
										<Typography variant="body1">{item.topping.note}</Typography>
									</Stack>
								</Stack>
								)}
								{ item.topping.minitopper && (
									<Stack direction="row" spacing={2} sx={{ "& .MuiTypography-root": { textTransform: "capitalize" } }}>
										{/* Image */}
										<Box sx={{ width: 80, minWidth: 80 }}>
											<img
												src={item.topping?.minitopimg ? `${path.imageServer}${path.imageDirectory}/${store.key}/${item.topping?.minitopimg}` : `${path.basename}/images/icon-product.svg`}
												style={{ width: "100%", height: "auto", objectFit: "cover" }}
											/>
										</Box>
										{/* Topping Details */}
										<Stack sx={{ flexGrow: 1 }}>
											<Stack direction="row" justifyContent="space-between">
												<Typography variant="body1">{item.topping?.minitopper}</Typography>
											</Stack>
										</Stack>
									</Stack>
								)}
								<Stack direction="row" sx={{ mt: "auto" }} justifyContent="flex-end">
									<Button variant="text" onClick={() => handleRemoveTopping(index)}>
										<Typography variant="body1" sx={{ color: "black", textDecoration: "underline", fontWeight: "bold" }}>
											Remove
										</Typography>
									</Button>
								</Stack>
							</Stack>
						)}
						<Stack spacing={0.5}>
							<Divider sx={{ borderColor: theme.palette.grey[400] }} />
							<Divider sx={{ borderColor: theme.palette.grey[400] }} />
						</Stack>
					</Stack>
				))}
			</Stack>
			<Stack sx={{ display: isEmpty(cart) ? "none" : "flex" }}>
				{/* SubTotal */}
				<Stack direction="row" sx={{ p: 2, borderBottom: 1, borderColor: theme.palette.grey[400], borderTop: 1 }} spacing={1}>
					<Typography variant="h5">Subtotal:</Typography>
					<Typography variant="h5">
						£{fCurrency(cart.reduce((acc, item) => {
							const itemTotal = (Number(item.final_price)) * item.quantity;
							const toppingTotal = item.topping ? (item.topping.sale_price ? item.topping.sale_price:0) * item.quantity : 0;
							//console.log(acc, itemTotal, toppingTotal);
							return acc + itemTotal + toppingTotal;
						}, 0))}
					</Typography>
				</Stack>
				<Stack direction="row" spacing={2} sx={{ p: 2 }}>
					{/* Checkout */}
					<Button
						variant="outlined"
						sx={{
							borderRadius: 0,
							borderColor: theme.palette.buttonBlack.main,
							"&:hover": { background: theme.palette.buttonBlack.main, borderColor: theme.palette.buttonBlack.main },
							p: 0.3,
							width: "50%",
						}}
						onClick={handleCheckout}>
						<Box
							sx={{
								border: "1px solid",
								borderColor: theme.palette.buttonBlack.main,
								width: "100%",
								height: "100%",
								background: theme.palette.buttonBlack.main,
								color: theme.palette.background.default,
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}>
							<Typography sx={{ p: 1.5, fontWeight: "bold" }}>Checkout</Typography>
						</Box>
					</Button>
					{/* Continue Shopping */}
					<Button
						variant="outlined"
						sx={{
							width: "50%",
							borderRadius: 0,
							borderColor: theme.palette.buttonBlack.main,
							color: theme.palette.buttonBlack.main,
							"&:hover": { background: theme.palette.buttonBlack.main, borderColor: theme.palette.buttonBlack.main },
						}}
						onClick={handleClose}>
						Continue Shopping
					</Button>
				</Stack>
				<Stack sx={{ height : '5rem'}}>&nbsp;</Stack>
			</Stack>

			{/* <Button onClick={handleClose}>Close</Button> */}
		</Stack>
	);
};

export default Cart;
